import { Container, MainTitle } from "@app/components";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util";
import { connect, withRouter } from "gl-commonui";
import React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { StudentVerificationPage } from "./components/studentverification";
import { setBreadcrumbs } from '@app/states/resource';

interface MergeStudentsPageProps extends RouteComponentProps<any> {
}
interface MergeStudentsPageStates {
}

@withRouter
export class MergeStudentsPage extends Component<MergeStudentsPageProps, MergeStudentsPageStates> {

    constructor(props) {
        super(props);
        setBreadcrumbs({
            mergestudents: {
                name: fmtMsg({ id: SchoolLocale.BreadTextMergeStudents }),
                id: props.match.params.mergedStudentId,
            }
        });
    }

    render() {
        return (
            <Container fullWidth>
                <MainTitle plain={fmtMsg({ id: GSAdminLocale.RegionManageStudentsMerge })} />
                <StudentVerificationPage {...this.props} />
            </Container>
        );
    }
}